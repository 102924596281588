$(document).foundation();

$('.nav-trigger').on('click',function() {
	$(this).siblings('nav').toggleClass('open');
});

$('.expander-group .expander-item .expander-title + .expander-content').hide();
$('.expander-group .expander-item .expander-title').on('click',function() {
	$(this).toggleClass('open').next('.expander-content').slideToggle('fast');
});
$('.expander-group .expander-item .expander-title:not(.open)').on('click',function() {
	$(this).parent('.expander-item').siblings('.expander-item').children('.expander-title').removeClass('open').next('.expander-content').slideUp('fast');
});
